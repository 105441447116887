module directives {
    export module integration {
        interface IInterfaceClientSummaryDirective extends ng.IScope {
            interfaceClientData: interfaces.integration.IInterfaceClientSummary[];
            isLoading: boolean;
            selectedNumberOfDays: number;
            DayList: Array<interfaces.applicationcore.IKeyValue>;
            LoadInterfaceClientData();
        }

        export class interfaceClientSummaryDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/integration/interfaceClientSummaryView.html';
            scope = {
            }

            public interfaceClientData: interfaces.integration.IInterfaceClientSummary[];

            constructor(
                private generalService: interfaces.applicationcore.IGeneralService,
                private $state: ng.ui.IStateService,
                private bsLoadingOverlayService,
                private interfaceClientService: interfaces.integration.IInterfaceClientService,
            ) {

            }

            link = ($scope: IInterfaceClientSummaryDirective, $element: ng.IAugmentedJQuery, $state) => {

                $scope.DayList = new Array<interfaces.applicationcore.IKeyValue>();
                $scope.DayList.push({ key: "1", value: 1 });
                $scope.DayList.push({ key: "2", value: 2 });
                $scope.DayList.push({ key: "7", value: 7 }); 

                $scope.selectedNumberOfDays = 1;

                $scope.LoadInterfaceClientData = () => {
                    $scope.isLoading = true;
                    return this.interfaceClientService.getInterfaceClientSummary($scope.selectedNumberOfDays).query({
                    }, (resultList) => {
                        $scope.interfaceClientData = resultList;
                        $scope.isLoading = false;
                    }).$promise;
                }

                $scope.LoadInterfaceClientData();

            }

            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $state, 
                    bsLoadingOverlayService, interfaceClientService) => new interfaceClientSummaryDirective(generalService, $state, bsLoadingOverlayService, interfaceClientService);
                directive.$inject = ["generalService", "$state", "bsLoadingOverlayService", "interfaceClientService"];

                return directive;
            }
        }

        angular.module("app").directive("gtsInterfaceClientSummary", interfaceClientSummaryDirective.factory());
    }
}